import React from 'react';

import {
  CodepenOutlined,
  PlusOutlined,
  SendOutlined,
  DownOutlined,
  ApiOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { navigateTo, useNavigator } from '../../useNavigator';
import { Button, Dropdown, Input, InputRef, Space, Tooltip } from 'antd';
import { serviceList } from '../../modules/apis/neo/serviceList';
import { assetVersions } from '../../modules/apis/neo/assetVersions';
import { serviceStatus } from '../../modules/apis/neo/serviceStatus';
import { ServiceStatusType } from '../../modules/apis/types';
import classNames from 'classnames';

export type AIMessage = {
  id: string;
  display: React.ReactNode;
};
export interface ModelSelectItem {
  key: string;
  label: string;
  version: string;
  id: string;
  status?: ServiceStatusType;
  versions?: Array<{ key: string; label: string; id: string }>;
}

export function ChatPromptForm({
  input,
  onInput,
  selectedItem,
  onSelect,
  disabled,
  onSubmit,
}: {
  input: string;
  onInput: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  selectedItem?: ModelSelectItem;
  onSelect: (item: ModelSelectItem) => void;
  disabled?: boolean;
  onSubmit: (event?: { preventDefault?: () => void }) => void;
}) {
  const [jobs, setJobs] = React.useState([] as Array<ModelSelectItem>);
  const navigator = useNavigator();
  const inputRef = React.useRef<InputRef>(null);
  const jobRef = React.useRef<ModelSelectItem>();
  jobRef.current = selectedItem;

  const handleVersion = (modelId) => {
    assetVersions({ id: modelId }).then((res_) => {
      if (res_.data) {
        const job = jobRef.current!;
        job.versions = [];
        res_.data.forEach((v) => {
          if (v.service_id) {
            job.versions!.push({
              key: v.name,
              label: v.name,
              id: v.service_id,
            });
          }
        });
        if (job.versions.length) {
          onSelect({
            ...job,
          });
        }
      }
    });
  };
  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    serviceList({
      type: 'ModelDeployment',
    }).then((res) => {
      if (res.data) {
        const seelctedJob = jobRef.current!;
        let matchItem!: ModelSelectItem;
        setJobs(
          res.data.map((item) => {
            const job = {
              key: item.model_id,
              label: `${item.domain}/${item.model_name}`,
              version: item.model_version,
              id: item.id,
            };
            if (selectedItem && item.id === selectedItem.id) {
              matchItem = { ...seelctedJob, ...job };
            }
            return job;
          })
        );
        if (!matchItem) {
          const firstItem = res.data[0];
          const selectedJob = {
            key: firstItem.model_id,
            label: `${firstItem.domain}/${firstItem.model_name}`,
            version: firstItem.model_version,
            id: firstItem.id,
          };
          matchItem = selectedJob;
        }
        onSelect({ ...matchItem });
        handleVersion(matchItem.key);
      }
    });
  }, []);

  const service_id = selectedItem?.id;
  const statusSuccessed =
    selectedItem?.status === ServiceStatusType.JobStatusTypePassing;
  React.useEffect(() => {
    if (service_id) {
      serviceStatus({
        id: service_id,
      }).then((res) => {
        if (res.data) {
          const job = jobRef.current!;
          job.status = res.data.status as ServiceStatusType;
          onSelect({ ...job });
        }
      });
    }
  }, [service_id]);

  return (
    <div>
      <div className='relative flex max-h-60 w-full grow flex-col overflow-hidden bg-background'>
        <Space className='mx-4 my-2 justify-between'>
          <div>
            <Dropdown
              trigger={['click']}
              menu={{
                items: jobs,
                selectable: true,
                selectedKeys: selectedItem ? [selectedItem.key] : undefined,
                onSelect: ({ selectedKeys }) => {
                  const matchJob = jobs.find(
                    (job) => job.key === selectedKeys[0]
                  )!;
                  onSelect(matchJob);
                  handleVersion(matchJob);
                },
              }}
              arrow
            >
              <Space>
                <CodepenOutlined rev={undefined} />
                {selectedItem ? selectedItem.label : 'Select a model service'}
                <DownOutlined rev={undefined} className='text-xs' />
              </Space>
            </Dropdown>
            {selectedItem?.versions ? (
              <Dropdown
                className='ml-4'
                trigger={['click']}
                menu={{
                  items: selectedItem.versions,
                  selectable: true,
                  selectedKeys: selectedItem
                    ? [selectedItem.version]
                    : undefined,
                  onSelect: ({ selectedKeys }) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    const matchVersion = selectedItem!.versions!.find(
                      (v) => v.key === selectedKeys[0]
                    )!;
                    navigator.push(
                      `/aiweb/playground?service_id=${matchVersion.id}`
                    );
                  },
                }}
                arrow
              >
                <Space>
                  <span
                    className={classNames({ 'text-red-600': !statusSuccessed })}
                  >
                    {selectedItem?.version}
                  </span>
                  <DownOutlined rev={undefined} className='text-xs' />
                </Space>
              </Dropdown>
            ) : null}
          </div>
          <Button type='text'>
            Model Setting
            <ApiOutlined
              rev={undefined}
              className='text-xs relative top-[-2px]'
            />
          </Button>
        </Space>
        <Input
          size='large'
          addonBefore={
            <Tooltip overlay='New Chat'>
              <PlusOutlined
                rev={undefined}
                onClick={() => {
                  navigateTo(
                    `/aiweb/playground?service_id=${selectedItem?.id}`
                  );
                }}
              />
            </Tooltip>
          }
          addonAfter={
            !statusSuccessed ? (
              <Tooltip overlay='Verson is not valid'>
                <WarningOutlined
                  rev={undefined}
                  onClick={() => {
                    onSubmit();
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip overlay='Send message'>
                <SendOutlined
                  rev={undefined}
                  onClick={() => {
                    onSubmit();
                  }}
                />
              </Tooltip>
            )
          }
          ref={inputRef}
          disabled={disabled || !statusSuccessed}
          placeholder='Send a message.'
          className='w-full rounded-none resize-none bg-transparent focus-within:outline-none sm:text-sm'
          autoFocus
          spellCheck={false}
          autoComplete='off'
          autoCorrect='off'
          name='message'
          onKeyDown={async (event) => {
            if (event.key === 'Enter') {
              onSubmit();
            }
          }}
          value={input}
          onChange={onInput}
        />
      </div>
    </div>
  );
}
