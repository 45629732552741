import React from 'react';

import { ChatPromptForm, ModelSelectItem } from './chat-prompt-form';
import { ButtonScrollToBottom } from './button-scroll-to-bottom';
import { ShareAltOutlined } from '@ant-design/icons';
import { ChatShareDialog } from './chat-share-dialog';
import { shareChat } from '../../modules/zettaai';
import { Button } from 'antd';
import { CoreMessage } from 'ai';
import { GlobalSettingsContext } from '../../../v2/context/global-settings';

export interface ChatPanelProps {
  id?: string;
  title?: string;
  input: string;
  onInput: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  selectedItem?: ModelSelectItem;
  onSelect: (item: ModelSelectItem) => void;
  isAtBottom: boolean;
  scrollToBottom: () => void;
  disabled?: boolean;
  onSubmit: (event?: { preventDefault?: () => void }) => void;
  messages: CoreMessage[];
}

export function ChatPanel({
  id,
  title,
  input,
  onInput,
  selectedItem,
  onSelect,
  isAtBottom,
  scrollToBottom,
  disabled,
  onSubmit,
  messages,
}: ChatPanelProps) {
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const { isMiniSidebar } = React.useContext(GlobalSettingsContext);
  const exampleMessages = [
    {
      heading: 'Sentiment Analysis',
      subheading:
        'You will be provided with a tweet, and your task is to classify its sentiment as positive, neutral, or negative.',
      message: `You will be provided with a tweet, and your task is to classify Sentiment analysis sentiment as positive, neutral, or negative.`,
    },
    {
      heading: 'Unstructured text parsing',
      subheading:
        'You will be provided with unstructured data, and your task is to parse it into JSON format.',
      message:
        'You will be provided with unstructured data, and your task is to parse unstructured text parsing into JSON format.',
    },
    {
      heading: 'Summarization',
      subheading:
        'You will be provided with a document and asked to summarize it.',
      message: `You will be provided with a document and asked to summarize Summarization.`,
    },
    {
      heading: 'Document Q&A',
      subheading: `You will be provided with a document and asked a question about it`,
      message: `You will be provided with a document and asked a question about it`,
    },
  ];

  const style = isMiniSidebar
    ? { width: 'calc(100% - 60px)', left: 60 }
    : { width: 'calc(100% - 200px)', left: 200 };
  return (
    <div
      className='fixed inset-x-0 bottom-12 w-full bg-gradient-to-b from-muted/30 from-0% to-muted/30 to-50% duration-300 ease-in-out animate-in dark:from-background/10 dark:from-10% dark:to-background/80 peer-[[data-state=open]]:group-[]:lg:pl-[250px] peer-[[data-state=open]]:group-[]:xl:pl-[300px]'
      style={style}
    >
      <ButtonScrollToBottom
        isAtBottom={isAtBottom}
        scrollToBottom={scrollToBottom}
      />

      <div className='mx-auto max-w-2xl px-4'>
        {messages.length === 0 && (
          <h3 className='text-lg font-bold mb-6'>
            Choose an example to get started:
          </h3>
        )}
        <div className='mb-4 grid grid-cols-2 gap-2 px-4 sm:px-0'>
          {messages.length === 0 &&
            exampleMessages.map((example, index) => (
              <div
                key={example.heading}
                className={`cursor-pointer rounded-lg border bg-white p-4 hover:bg-zinc-50 dark:bg-zinc-950 dark:hover:bg-zinc-900 ${
                  index > 1 && 'hidden md:block'
                }`}
                onClick={() => {
                  onInput({
                    target: {
                      value: example.message,
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  } as any);
                  onSubmit();
                }}
              >
                <div className='text-sm font-semibold'>{example.heading}</div>
                <div className='text-sm text-zinc-600'>
                  {example.subheading}
                </div>
              </div>
            ))}
        </div>

        {messages?.length >= 2 ? (
          <div className='flex h-12 items-center justify-center'>
            <div className='flex space-x-2'>
              {id && title ? (
                <>
                  <Button
                    ghost
                    size='small'
                    onClick={() => setShareDialogOpen(true)}
                  >
                    <ShareAltOutlined className='mr-2' rev={undefined} />
                    Share
                  </Button>
                  <ChatShareDialog
                    open={shareDialogOpen}
                    afterOpenChange={setShareDialogOpen}
                    onCopy={() => setShareDialogOpen(false)}
                    shareChat={shareChat}
                    chat={{
                      id,
                      title,
                      messages,
                    }}
                  />
                </>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className='space-y-4 border-t bg-gray-50 shadow-lg sm:rounded-t-xl sm:border'>
          <ChatPromptForm
            input={input}
            onInput={onInput}
            selectedItem={selectedItem}
            onSelect={onSelect}
            disabled={disabled}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}
