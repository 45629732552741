/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { spinner } from './spinner';
import { CodeBlock } from '../CodeBlock';
import { MemoizedReactMarkdown } from '../MemoizedReactMarkdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
// eslint-disable-next-line import/no-unresolved
import { StreamableValue, readStreamableValue } from 'ai/rsc';
import Icon from '../../../v2/components/Icon';
import { Avatar } from '../Avatar';

export const useStreamableText = (
  content: string | StreamableValue<string>
) => {
  const [rawContent, setRawContent] = useState(
    typeof content === 'string' ? content : ''
  );

  useEffect(() => {
    (async () => {
      if (typeof content === 'object') {
        let value = '';
        // eslint-disable-next-line no-restricted-syntax
        for await (const delta of readStreamableValue(content)) {
          if (typeof delta === 'string') {
            setRawContent((value += delta));
          }
        }
      }
    })();
  }, [content]);

  return rawContent;
};

// Different types of message bubbles.

export function UserMessage({ children }: { children: React.ReactNode }) {
  return (
    <div className='group relative flex items-start md:-ml-12'>
      <div className='flex shrink-0 select-none items-center justify-center rounded-[12px] border bg-background shadow-sm overflow-hidden'>
        <Avatar className='h-[24px]' />
      </div>
      <div className='ml-4 flex-1 space-y-2 overflow-hidden pl-2'>
        {children}
      </div>
    </div>
  );
}

export function BotMessage({
  content,
  className,
}: {
  content: string | StreamableValue<string>;
  className?: string;
}) {
  const text = useStreamableText(content);

  return (
    <div className={cn('group relative flex items-start md:-ml-12', className)}>
      <div className='flex size-[24px] shrink-0 select-none items-center justify-center rounded-[12px] border bg-primary text-primary-foreground shadow-sm overflow-hidden'>
        <Icon className='h-[24px]' iconName='favicon' iconType='png' />
      </div>
      <div className='ml-4 flex-1 space-y-2 overflow-hidden px-1'>
        <MemoizedReactMarkdown
          className='prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0'
          remarkPlugins={[remarkGfm, remarkMath]}
          components={{
            p({ children }) {
              return <p className='mb-2 last:mb-0'>{children}</p>;
            },
            // eslint-disable-next-line @typescript-eslint/no-shadow
            code({ inline, className, children, ...props }) {
              if (children.length) {
                if (children[0] === '▍') {
                  return (
                    <span className='mt-1 animate-pulse cursor-default'>▍</span>
                  );
                }

                children[0] = (children[0] as string).replace('`▍`', '▍');
              }

              const match = /language-(\w+)/.exec(className || '');

              if (inline) {
                return (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              }

              return (
                <CodeBlock
                  key={Math.random()}
                  language={(match && match[1]) || ''}
                  value={String(children).replace(/\n$/, '')}
                  {...props}
                />
              );
            },
          }}
        >
          {text}
        </MemoizedReactMarkdown>
      </div>
    </div>
  );
}

export function BotCard({
  children,
  showAvatar = true,
}: {
  children: React.ReactNode;
  showAvatar?: boolean;
}) {
  return (
    <div className='group relative flex items-start md:-ml-12'>
      <div
        className={cn(
          'flex size-[24px] shrink-0 select-none items-center justify-center rounded-[12px] border bg-primary text-primary-foreground shadow-sm overflow-hidden',
          !showAvatar && 'invisible'
        )}
      >
        <Icon className='h-[24px]' iconName='favicon' iconType='png' />
      </div>
      <div className='ml-4 flex-1 pl-2 space-y-2'>{children}</div>
    </div>
  );
}

export function SystemMessage({ children }: { children: React.ReactNode }) {
  return (
    <div
      className={
        'mt-2 flex items-center justify-center gap-2 text-xs text-gray-500'
      }
    >
      <div className={'max-w-[600px] flex-initial p-2'}>{children}</div>
    </div>
  );
}

export function SpinnerMessage() {
  return (
    <div className='group relative flex items-start md:-ml-12'>
      <div className='flex size-[24px] shrink-0 select-none items-center justify-center rounded-[12px] border bg-primary text-primary-foreground shadow-sm overflow-hidden'>
        <Icon className='h-[24px]' iconName='favicon' iconType='png' />
      </div>
      <div className='ml-4 h-[24px] w-[24px] overflow-hidden space-y-2'>
        {spinner}
      </div>
    </div>
  );
}
