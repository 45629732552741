import { produce } from 'immer';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { setDeep } from '../../../utils/object';
import { ChartOptionsV2 } from '../../chart/types-v2';
import { DefaultBarChartUiOptions } from '../../chart/utils/echart';
import { SelectChartByIdState, updateChart } from '../ChartsState';

export type YCol = {
  colName: string;
  customLabel?: string;
  valueType: string;
  chartType: string;
  color?: string;
};

export type BarChartUiOptions = {
  version?: number;
  chartOptions?: {
    showLegend?: boolean;
    enableStacking?: boolean;
    normalizeData?: boolean;
  };
  xAxisOptions?: {
    label?: string;
    tickFormat?: string;
    logarithmic?: boolean;
    sortValues?: boolean;
    reverseValue?: boolean;
  };
  yAxisOptions?: {
    label?: string;
    tickFormat?: string;
    labelFormat?: string;
    logarithmic?: boolean;
    enableRightYAxis?: boolean;
    min?: number;
  };
  dataOptions?: {
    groupByCol?: string;
    xCol?: {
      colName: string;
      customLabel?: string;
      valueType: string;
      sortValues?: boolean
    };
    yCols?: YCol[];
    groupByYCols?: YCol[];
  };
};

export type SetBarChartOptions = (n: ChartOptionsV2) => void;

export const BarChartOptionsState = atomFamily<ChartOptionsV2, string>({
  key: 'BarChartOptionsState',
  default: {
    displayName: 'Bar chart',
    uiOptions: DefaultBarChartUiOptions,
  },
});

export function useBarChartOptions(chartId: string) {
  const [chart, setChart] = useRecoilState(
    SelectChartByIdState({ id: chartId })
  );
  const [barChartOptions, setBarChartOptionsState] = useRecoilState(
    BarChartOptionsState(chartId)
  );

  const [updating, setUpdating] = useState(false);

  const debouncedEventHandler = useMemo(
    () =>
      debounce((nBarChartOptions: ChartOptionsV2) => {
        // trigger rerender chart

        const nChart = {
          ...chart,
          ...nBarChartOptions,
          type: 'bar',
          // TODO: this is due to the incorrect type def from backend swagger (id being)
          id: chart?.id || '',
        } as const;

        setChart(nChart);

        // TODO: call api to update chart
        // eslint-disable-next-line
        // console.log('api update & trigger rerender chart', nChart);
        updateChart({ chart: nChart });

        setUpdating(false);
      }, 600),
    [chart, setChart]
  );

  const setBarChartOptions = useCallback(
    (nBarChartOptions: ChartOptionsV2) => {
      setUpdating(true);

      // update options
      setBarChartOptionsState(nBarChartOptions);
      // trigger rerender by updating chart
      debouncedEventHandler(nBarChartOptions);
    },
    [debouncedEventHandler]
  );

  const setBarChartOptionsByMap = useCallback(
    // eslint-disable-next-line
    (valueMap: Record<string, any>) => {
      setBarChartOptions(
        produce(barChartOptions, (draft) => {
          setDeep(draft, valueMap);
        })
      );
    },
    [barChartOptions, setBarChartOptions]
  );

  return [
    barChartOptions,
    setBarChartOptions,
    updating,
    setBarChartOptionsByMap,
  ] as const;
}
