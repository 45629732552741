/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { CoreMessage } from 'ai';
import {
  BotCard,
  BotMessage,
  SpinnerMessage,
  UserMessage,
} from '../stocks/message';
import { Stocks, Stock, Events, Purchase, spinner } from '../stocks';
import { nanoid } from 'nanoid';

export interface ChatList {
  messages: CoreMessage[];
  isLogined?: boolean;
  isShared: boolean;
  isLoading: boolean;
}

export function ChatList({
  messages,
  isLogined,
  isShared,
  isLoading,
}: ChatList) {
  const mlen = messages.length;
  if (!mlen) {
    return null;
  }

  let lastRobot = false;
  return (
    <div className='relative mx-auto max-w-2xl px-4'>
      {!isShared && !isLogined ? (
        <>
          <div className='group relative mb-4 flex items-start md:-ml-12'>
            <div className='bg-background flex size-[25px] shrink-0 select-none items-center justify-center rounded-md border shadow-sm'>
              <WarningOutlined rev={undefined} />
            </div>
            <div className='ml-4 flex-1 space-y-2 overflow-hidden px-1'>
              <p className='text-muted-foreground leading-normal'>
                Please{' '}
                <Link to='/aiweb/login' className='underline'>
                  log in
                </Link>{' '}
                or{' '}
                <Link to='/aiweb/signup' className='underline'>
                  sign up
                </Link>{' '}
                to save and revisit your chat history!
              </p>
            </div>
          </div>
          <Divider className='my-4' />
        </>
      ) : null}

      {messages.map((message, index) => {
        // eslint-disable-next-line no-multi-assign
        const id =
          ((message as any).id = (message as any).id || nanoid()) +
          message.content.at(-1);
        const divider = index < mlen - 1 && <Divider className='my-4' />;
        lastRobot = index + 1 === mlen && message.role !== 'user';
        switch (message.role) {
          case 'tool':
            return message.content.map((tool) => {
              return tool.toolName === 'listStocks' ? (
                <BotCard key={id}>
                  <Stocks props={tool.result} />
                </BotCard>
              ) : tool.toolName === 'showStockPrice' ? (
                <BotCard key={id}>
                  <Stock props={tool.result} />
                </BotCard>
              ) : tool.toolName === 'showStockPurchase' ? (
                <BotCard key={id}>
                  <Purchase props={tool.result} />
                </BotCard>
              ) : tool.toolName === 'getEvents' ? (
                <BotCard key={id}>
                  <Events props={tool.result} />
                </BotCard>
              ) : null;
            });
          case 'user':
            return (
              <div key={id}>
                <UserMessage>{message.content as string}</UserMessage>
                {divider}
              </div>
            );
          case 'assistant':
          default:
            return (
              typeof message.content === 'string' && (
                <div key={id}>
                  <BotMessage content={message.content} />
                  {divider}
                </div>
              )
            );
        }
      })}

      {isLoading &&
        (lastRobot ? (
          <div className='h-[24px] w-[24px] overflow-hidden'>{spinner}</div>
        ) : (
          <>
            <Divider className='my-4' />
            <SpinnerMessage />
          </>
        ))}
    </div>
  );
}
