import { useState } from "react"
import Logo from "../../components/app/Logo"
import classNames from "classnames"

import './onboarding.scss'
import { useNavigate } from "react-router-dom"

const PLANS = [
  {
    name: 'Free',
    id: 'free',
    price: 0,
    description: 'Great for developers, traders, or anyone getting started.',
  },
  {
    name: 'Builder',
    id: 'builder',
    price: 49,
    description: 'Ultimate scalability for professional individuals working in web3.',
  },
  {
    name: 'Enterprise',
    id: 'enterprise',
    price: undefined,
    description: 'Volume pricing and enterprise support for institutional use cases.',
  },
]

const ChoosePlan = () => {
  const [selectedPlan, setSelectedPlan] = useState('free')

  const navigate = useNavigate()

  const onNextStep = () => {
    if (selectedPlan === 'free') {
      navigate('/v2/onboarding/subscribe?plan=free')
    } else {
      navigate('/v2/onboarding/subscribe')
    }
  }


  return <div className='min-h-[calc(100vh-380px)] pt-16'>
    <div className='max-w-[480px] mx-[auto]'>
      <div className='flex justify-center'>
        <Logo />
      </div>

      <div className='bg-[#fff] mt-8 pb-10'>
        <div className='bg-[#fff] px-8 py-4 flex justify-between items-center border-b'>
          <span className=' font-bold'>
            Choose your plan
          </span>
        </div>

        <div className="flex p-8 flex-wrap gap-4 justify-between">
          {
            PLANS.map((plan, index) => {
              if (plan.id === 'enterprise') {
                return <div
                  key={index}
                  onClick={() => { setSelectedPlan(plan.id) }}
                  className={
                    classNames("border w-full rounded cursor-pointer flex justify-between", {
                      'border-[#263DFF]': selectedPlan === plan.id,
                      'bg-[#f4f4fe]': selectedPlan === plan.id,
                    })
                  }>
                  <div className="w-[200px] p-3 ">
                    <a className='text-primary' href='mailto:team@zettablock.com'>
                      <div className="text-[#263DFF] font-semibold text-lg">
                        {plan.name}
                      </div>
                      <div className="mt-2 text-[#777A80] text-sm mb-8">
                        {plan.description}
                      </div>

                      <div className="text-[#000] text-[18px] font-semibold">
                        Contact Support
                      </div>
                    </a>
                  </div>
                  <a className='text-primary' href='mailto:team@zettablock.com'>
                    <div className="w-[200px] h-full bgimg">{' '}</div>
                  </a>
                </div>
              }

              return <div
                key={index}
                onClick={() => { setSelectedPlan(plan.id) }}
                className={
                  classNames("border w-[200px] p-3 rounded cursor-pointer", {
                    'border-[#263DFF]': selectedPlan === plan.id,
                    'bg-[#f4f4fe]': selectedPlan === plan.id,
                  })
                }>
                <div className="text-[#263DFF] font-semibold text-lg">
                  {plan.name}
                </div>
                <div className="mt-2 text-[#777A80] text-sm mb-8">
                  {plan.description}
                </div>

                <div>
                  {
                    <span><span className="font-semibold text-xl">${plan.price}</span> / Month</span>
                  }
                </div>
              </div>
            })
          }
        </div>

        <div className="px-8 pb-2 flex justify-center mt-4">
          {/* <button className="btn bg-[#fafafa] border-[#e2e2e3] text-[#333] w-[200px] hover:text-[#fff]" onClick={() => {
            navigate('/v2/')
           }}>
            Back
          </button> */}
          {
            selectedPlan === 'enterprise' ? <button className="btn btn-primary bg-[#263DFF] w-[200px]">
              Contact Support
            </button> : <button className="btn btn-primary bg-[#263DFF] w-[200px]" onClick={onNextStep}>
              Continue
            </button>
          }
        </div>
      </div>

    </div>
  </div>
}


export default ChoosePlan