import { atom, useRecoilState } from 'recoil';
import { useEffect, useMemo } from 'react';
import { getRecoilState } from '../../../modules/recoil/utils';
import { authMe, authMeDTO } from '../../modules/apis/neo/authMe';
import { AuthError } from './AuthError';
import { WindowPopup } from './WindowPopup';
import { httpClient } from '../../modules/http-client';
import { useNavigator } from '../../useNavigator';
import { RiskRequestPlugin } from '../../modules/http-client/plugins/RiskRequestPlugin';
import { Deferred } from './util';

httpClient.defaults.baseURL = process.env.REACT_APP_ZETTAAI_BACKEND_API;

export const authPromise = authMe({}, {
  authorize: true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any) as Promise<authMeDTO.Res> & { authorize?: boolean };

export const riskDeferred = new Deferred();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RiskRequestPlugin.getRiskInfo = (config: any) => {
  if (config.authorize) {
    return {};
  }
  return riskDeferred.promise;
};

export const AIAuthState = atom<authMeDTO.UserRes | null>({
  key: 'AIAuthState',
  default: null,
});

export const getAIAuth = async () => {
  const auth = await getRecoilState(AIAuthState);
  return auth;
};

export function useAIAuth() {
  const navigator = useNavigator();
  const [auth, setAuth] = useRecoilState(AIAuthState);

  useMemo(() => {
    if (!auth && !getRecoilState(AIAuthState) && !authPromise.authorize) {
      authPromise.authorize = true;
      authPromise.then(
        (res) => {
          if (res.data?.user) {
            setAuth(res.data);
          }
        },
        () => {
          navigator.push('/aiweb/login');
        }
      );
    }
  }, []);
  useEffect(() => {
    if (auth?.access_token) {
      const apiKey = auth?.profile?.api_keys?.find((item) => item.is_default);
      riskDeferred.resolve({
        'X-API-KEY': apiKey?.id,
        Authorization: `Bearer ${auth.access_token}`,
      });
    }
  }, [auth]);

  const getIsOwner = <T extends authMeDTO.User>(user?: T | null) => {
    if (auth?.user?.id && auth.user.id === user?.id) {
      return true;
    }

    return false;
  };

  return [auth, { getIsOwner, setAuth }] as const;
}

export function useOpenAuth<T>({
  authUrl,
  timeout = 1000 * 10,
  // uxMode = 'popup',
  onSuccess,
  onError,
}: {
  authUrl: string;
  timeout?: number;
  // uxMode?: 'popup'
  onSuccess?: (data: T) => void;
  onError?: (err: AuthError) => void;
}) {
  const currentWindow = useMemo(() => {
    return new WindowPopup({
      url: authUrl,
      timeout,
    });
  }, []);

  return () => {
    currentWindow.listenOnChannel().then(onSuccess, onError);
  };
}
