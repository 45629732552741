import { useContext } from 'react';
import {
  Navigator,
  To,
  UNSAFE_NavigationContext,
} from 'react-router-dom';

let globalNavigator: Navigator;
let navigateLength = 0;
let navigateCurrent = 0;
export function useNavigator(defaultPathname = '/aiweb/models') {
  const idDefault = defaultPathname === '/aiweb/models';
  const { navigator } = useContext(UNSAFE_NavigationContext);
  if (globalNavigator && idDefault) {
    return globalNavigator;
  }
  const iNavigator = {
    createHref: navigator.createHref,
    go(delta: number) {
      if (delta > 0) {
        if (delta + navigateCurrent > navigateLength) {
          navigateCurrent = navigateLength;
        } else {
          navigateCurrent += delta;
        }
        navigator.go(delta);
      } else if (delta < 0) {
        if (delta + navigateCurrent < 0) {
          navigateCurrent = 0;
          navigator.replace(defaultPathname);
        } else {
          navigateCurrent -= delta;
          navigator.go(delta);
        }
      }
    },
    replace: navigator.replace,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    push(to: To, state?: any) {
      if (typeof to === 'string' && to === window.location.pathname) {
        return;
      }
      navigateLength++;
      navigateCurrent = navigateLength;
      navigator.push(to, {
        ...state,
        back: window.location.pathname,
      });
    },
  };
  if (idDefault) {
    globalNavigator = iNavigator;
  }
  return iNavigator;
}

export function getNavigator() {
  return globalNavigator;
}

export function navigateTo(href: string, target = '_blank') {
  Object.assign(document.createElement('a'), {
    target,
    rel: 'noopener noreferrer',
    href,
  }).click();
}
