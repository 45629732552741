/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import Icon from '../../v2/components/Icon';
import { useState } from 'react';
import { useQueryClone } from './QueryBuilderCloneHooks';
import { toast } from 'react-toastify';
import QueryBuilderSettingModal from './QueryBuilderSettingModal';
import Modal from '../../components/modals/Modal';
import { useQueryRunV3 } from './QueryBuilderHooksV3';
import { useNavigate } from 'react-router-dom';
import QueryBuilderPublishModal from './QueryBuilderPublishModal';
import { useQueryIdV3 } from './QueryPadStateV3';
import useAuth from '../../hooks/auth';
import QueryBuilderUnpublishModal from './QueryBuilderUnpublishModal';
import { COMMUNITY } from '../auth/AuthState';
import { Tooltip } from 'antd';

export const Button = ({
  iconName,
  onClick,
  text,
  className,
  disabled,
  tooltip,
}: {
  iconName: string;
  onClick?: () => void;
  text: string;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
}) => {
  return (
    <Tooltip title={tooltip}>
      <div
        className={classNames(
          'cursor-pointer px-[5px] py-[8px] flex gap-[5px] items-center text-[12px] font-[500]',
          className
        )}
        onClick={() => {
          if (disabled) return;
          onClick?.();
        }}
      >
        <Icon className='w-[14px] h-[14px]' iconName={iconName} />
        {text}
      </div>
    </Tooltip>
  );
};

export const UseButton = ({ onClick, disabled }: any) => {
  return (
    <Button
      tooltip={disabled ? 'Query with parameters is not supported yet' : ''}
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        'ml-[5px] bg-[#DAE0FF] text-[#263DFF] px-[14.5px] rounded-[6px]',
        {
          'cursor-not-allowed': disabled,
        }
      )}
      iconName='bluelight'
      text='USE'
    />
  );
};

const QueryBuilderButtonGroupV2 = ({
  onUseButtonClick,
  newBuild,
  disabled,
}: any) => {
  const [openSetting, setOpenSetting] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);

  const navigate = useNavigate();

  const [{ rpcStatusDel, queryValues }, { deleteQuery }] = useQueryRunV3({
    queryPath: '',
  });

  const [clone, { isCloning }] = useQueryClone({
    queryPath: `/my-workspace/${'menu'}`,
  });
  const [{ auth }, { getIsOwner }] = useAuth();
  const queryId = useQueryIdV3();
  const published = queryValues.access === 'public';
  const isAuthor = !queryId || getIsOwner(queryValues);

  if (newBuild) {
    return null;
  }
  return (
    <div className='flex gap-[5px] bg-[#fff]'>
      {published && (
        <Button
          iconName='fork'
          text='Fork'
          onClick={async () => {
            await clone(true);
            toast.success('Query cloned successfully', {
              autoClose: 3000,
              position: 'top-center',
            });
          }}
          disabled={isCloning}
        />
      )}
      {/* <Button
      iconName="share"
      text="Share"
    /> */}
    {isAuthor && <>
      {
        published ? <div
          data-tip='Please upgrade the plan to make userapi private'
          className={classNames({
            'tooltip tooltip-warning opacity-90':
              auth?.tenant === COMMUNITY,
          })}
        ><Button
            disabled={auth?.tenant === COMMUNITY}
            iconName="publish"
            text="Unpublish"
            onClick={() => setOpenPublish(true)}
          /></div> : <Button
          iconName="publish"
          text="Publish"
          onClick={() => setOpenPublish(true)}
        />
      }
      <Button
        iconName="settings"
        text="Settings"
        onClick={() => setOpenSetting(true)}
      />
      <Button
        iconName="delete"
        text="Delete"
        onClick={() => setOpenDelete(true)}
      />
      {onUseButtonClick && <UseButton onClick={onUseButtonClick} disabled={disabled} />}
    </>}

      {openSetting && (
        <QueryBuilderSettingModal
          isV2
          openSetting={openSetting}
          setOpenSetting={setOpenSetting}
        />
      )}
      {openPublish && !published && (
        <QueryBuilderPublishModal
          openPublish={openPublish}
          setOpenPublish={setOpenPublish}
        />
      )}
      {openPublish && published && (
        <QueryBuilderUnpublishModal
          openPublish={openPublish}
          setOpenPublish={setOpenPublish}
          isV2
        />
      )}
      <Modal
        open={openDelete}
        className='flex flex-col space-y-4'
        onClickBackdrop={() => setOpenDelete(false)}
      >
        <h3 className='flex items-center space-x-2 font-bold text-lg'>
          <span>Deleting the query</span>
        </h3>
        <div className='flex-1 pb-2 overflow-y-auto max-h-[50vh] space-y-1'>
          Are you sure?
        </div>
        <div className='modal-action items-center'>
          <button
            type='button'
            className='btn btn-outline'
            onClick={async () => {
              setOpenDelete(false);
            }}
          >
            CANCEL
          </button>
          <button
            type='button'
            className={classNames('btn btn-warning', {
              loading: rpcStatusDel.isLoading,
            })}
            onClick={async () => {
              const success = await deleteQuery();
              if (success) {
                setOpenDelete(false);
                // track('Query - Delete query', {
                //   from: 'query-builder',
                //   queryId,
                // })
                navigate('/v2/workspace/queries');
              }
            }}
          >
            DELETE
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default QueryBuilderButtonGroupV2;
