import { Chat } from '../../../components/chat';
import { useAIAuth } from '../../../components/OpenAuth/useOpenAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { getChat } from '../../../modules/zettaai';

export default function ChatPage() {
  const { id } = useParams();
  const [auth] = useAIAuth();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [chat, setChat] = useState<any>();
  const userId = auth?.user?.id as string;

  useMemo(async () => {
    const _chat = await getChat(id!, userId);
    setChat(_chat);
  }, []);

  if (!chat || chat?.userId !== auth?.user?.id) {
    navigate('/aiweb/playground');
  }

  return <Chat id={chat?.id} />;
}
