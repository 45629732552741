/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from 'zod';
import { safeParseJSON } from '@ai-sdk/provider-utils';
import { StockSkeleton } from './stock-skeleton';
import { StocksSkeleton } from './stocks-skeleton';
import { EventsSkeleton } from './events-skeleton';
import { ComponentType, FC, lazy, ReactNode, Suspense } from 'react';
import { BotCard, BotMessage } from './message';
import { sleep } from './stock-purchase';
import { nanoid } from 'nanoid';
import { InvalidToolArgumentsError, NoSuchToolError } from 'ai';

export { spinner } from './spinner';
export { BotCard, BotMessage, SystemMessage } from './message';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function dynamic<T extends ComponentType<any>>(
  factory: () => Promise<T>,
  Loading: FC
) {
  const Loadable = lazy(() =>
    factory().then((Com) => {
      return { default: Com };
    })
  );

  return (props) => (
    <Suspense fallback={<Loading />}>
      <Loadable {...props} />
    </Suspense>
  );
}

const Stock = dynamic(
  () => import('./stock').then((mod) => mod.Stock),
  () => <StockSkeleton />
);

const Purchase = dynamic(
  () => import('./stock-purchase').then((mod) => mod.Purchase),
  () => (
    <div className='h-[375px] rounded-xl border bg-zinc-950 p-4 text-green-400 sm:h-[314px]' />
  )
);

const Stocks = dynamic(
  () => import('./stocks').then((mod) => mod.Stocks),
  () => <StocksSkeleton />
);

const Events = dynamic(
  () => import('./events').then((mod) => mod.Events),
  () => <EventsSkeleton />
);

export const stockTools = {
  listStocks: {
    description: 'List three imaginary stocks that are trending.',
    parameters: z.object({
      stocks: z.array(
        z.object({
          symbol: z.string().describe('The symbol of the stock'),
          price: z.number().describe('The price of the stock'),
          delta: z.number().describe('The change in price of the stock'),
        })
      ),
    }),
    async *generate({ stocks }, dispatch) {
      yield (
        <BotCard>
          <StocksSkeleton />
        </BotCard>
      );

      await sleep(1000);

      const toolCallId = nanoid();

      dispatch([
        {
          id: nanoid(),
          role: 'assistant',
          content: [
            {
              type: 'tool-call',
              toolName: 'listStocks',
              toolCallId,
              args: { stocks },
            },
          ],
        },
        {
          id: nanoid(),
          role: 'tool',
          content: [
            {
              type: 'tool-result',
              toolName: 'listStocks',
              toolCallId,
              result: stocks,
            },
          ],
        },
      ]);

      return (
        <BotCard>
          <Stocks props={stocks} />
        </BotCard>
      );
    },
  },
  showStockPrice: {
    description:
      'Get the current stock price of a given stock or currency. Use this to show the price to the user.',
    parameters: z.object({
      symbol: z
        .string()
        .describe(
          'The name or symbol of the stock or currency. e.g. DOGE/AAPL/USD.'
        ),
      price: z.number().describe('The price of the stock.'),
      delta: z.number().describe('The change in price of the stock'),
    }),
    async *generate({ symbol, price, delta }, dispatch) {
      yield (
        <BotCard>
          <StockSkeleton />
        </BotCard>
      );

      await sleep(1000);

      const toolCallId = nanoid();

      dispatch([
        {
          id: nanoid(),
          role: 'assistant',
          content: [
            {
              type: 'tool-call',
              toolName: 'showStockPrice',
              toolCallId,
              args: { symbol, price, delta },
            },
          ],
        },
        {
          id: nanoid(),
          role: 'tool',
          content: [
            {
              type: 'tool-result',
              toolName: 'showStockPrice',
              toolCallId,
              result: { symbol, price, delta },
            },
          ],
        },
      ]);

      return (
        <BotCard>
          <Stock props={{ symbol, price, delta }} />
        </BotCard>
      );
    },
  },
  showStockPurchase: {
    description:
      'Show price and the UI to purchase a stock or currency. Use this if the user wants to purchase a stock or currency.',
    parameters: z.object({
      symbol: z
        .string()
        .describe(
          'The name or symbol of the stock or currency. e.g. DOGE/AAPL/USD.'
        ),
      price: z.number().describe('The price of the stock.'),
      numberOfShares: z
        .number()
        .optional()
        .describe(
          'The **number of shares** for a stock or currency to purchase. Can be optional if the user did not specify it.'
        ),
    }),
    async generate({ symbol, price, numberOfShares = 100 }, dispatch) {
      const toolCallId = nanoid();

      if (numberOfShares <= 0 || numberOfShares > 1000) {
        dispatch([
          {
            id: nanoid(),
            role: 'assistant',
            content: [
              {
                type: 'tool-call',
                toolName: 'showStockPurchase',
                toolCallId,
                args: { symbol, price, numberOfShares },
              },
            ],
          },
          {
            id: nanoid(),
            role: 'tool',
            content: [
              {
                type: 'tool-result',
                toolName: 'showStockPurchase',
                toolCallId,
                result: {
                  symbol,
                  price,
                  numberOfShares,
                  status: 'expired',
                },
              },
            ],
          },
          {
            id: nanoid(),
            role: 'system',
            content: `[User has selected an invalid amount]`,
          },
        ]);

        return <BotMessage content={'Invalid amount'} />;
      }
      dispatch([
        {
          id: nanoid(),
          role: 'assistant',
          content: [
            {
              type: 'tool-call',
              toolName: 'showStockPurchase',
              toolCallId,
              args: { symbol, price, numberOfShares },
            },
          ],
        },
        {
          id: nanoid(),
          role: 'tool',
          content: [
            {
              type: 'tool-result',
              toolName: 'showStockPurchase',
              toolCallId,
              result: {
                symbol,
                price,
                numberOfShares,
              },
            },
          ],
        },
      ]);

      return (
        <BotCard>
          <Purchase
            props={{
              numberOfShares,
              symbol,
              price: +price,
              status: 'requires_action',
            }}
          />
        </BotCard>
      );
    },
  },
  getEvents: {
    description:
      'List funny imaginary events between user highlighted dates that describe stock activity.',
    parameters: z.object({
      events: z.array(
        z.object({
          date: z
            .string()
            .describe('The date of the event, in ISO-8601 format'),
          headline: z.string().describe('The headline of the event'),
          description: z.string().describe('The description of the event'),
        })
      ),
    }),
    async *generate({ events }, dispatch) {
      yield (
        <BotCard>
          <EventsSkeleton />
        </BotCard>
      );

      await sleep(1000);

      const toolCallId = nanoid();

      dispatch([
        {
          id: nanoid(),
          role: 'assistant',
          content: [
            {
              type: 'tool-call',
              toolName: 'getEvents',
              toolCallId,
              args: { events },
            },
          ],
        },
        {
          id: nanoid(),
          role: 'tool',
          content: [
            {
              type: 'tool-result',
              toolName: 'getEvents',
              toolCallId,
              result: events,
            },
          ],
        },
      ]);

      return (
        <BotCard>
          <Events props={events} />
        </BotCard>
      );
    },
  },
};

export async function toolCall<
  K extends string,
  O extends Record<
    K,
    {
      description: string;
      parameters: any;
      generate: (
        props: any,
        dispatch
      ) => Generator | AsyncGenerator | Promise<any>;
    }
  >
>(
  tools: O,
  value: {
    toolName: K;
    toolCallId: string;
    args: any;
  },
  dispatch
) {
  const { toolName } = value;

  if (!tools) {
    throw new NoSuchToolError({ toolName });
  }

  const tool = tools[toolName];
  if (!tool) {
    throw new NoSuchToolError({
      toolName,
      availableTools: Object.keys(tools),
    });
  }

  const parseResult = safeParseJSON({
    text: value.args,
    schema: tool.parameters,
  });

  if (parseResult.success === false) {
    throw new InvalidToolArgumentsError({
      toolName,
      toolArgs: value.args,
      cause: parseResult.error,
    });
  }

  const result = tool.generate(parseResult.value, dispatch);
  if (
    result instanceof Promise ||
    (result && typeof result === 'object' && 'then' in result)
  ) {
    const node = await (result as Promise<ReactNode>);
    dispatch(node);
  } else if (
    result &&
    typeof result === 'object' &&
    Symbol.asyncIterator in result
  ) {
    const it = result as AsyncGenerator<React.ReactNode, React.ReactNode, void>;
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const { done, value } = await it.next();
      dispatch(value);
      if (done) break;
    }
  } else if (
    result &&
    typeof result === 'object' &&
    Symbol.iterator in result
  ) {
    const it = result as Generator<React.ReactNode, React.ReactNode, void>;
    while (true) {
      const { done, value } = it.next();
      dispatch(value);
      if (done) break;
    }
  } else {
    dispatch(value);
  }
}

export { Stock, Purchase, Stocks, Events };
