/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames"
import { useEffect, useState } from "react"
import { IoIosMore, IoIosArrowDown, IoIosArrowForward } from "react-icons/io"
import { Button, Dropdown, Form, Input, Modal, Popconfirm, Select, Table } from "antd"
import { ImTable2 } from "react-icons/im"
import { createProject, deleteProject, deleteTable, getAllZettaTables, getProjects, getProjectsWithChildren, addTablesToProject, updateProjectMetadata } from "./apis"
import { toast } from "react-toastify"
import { composeDefaultProjectTables, composeTableDataSource } from "./utils"
import { BiEdit, BiMove } from "react-icons/bi"
import { MdDelete } from "react-icons/md"
import { Link } from "react-router-dom"
import { Key } from "antd/es/table/interface"
import folderIcon from './folder-1.svg'
import tableIcon from './table.svg'
import addProjectIcon from './addp.svg'
import { ItemType } from 'antd/es/menu/interface';
import useAuth from "../../hooks/auth"
import { track } from "../tracking"
import LoadingIndicator from "../../components/LoadingIndicator"


const TableWorkspace = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editField, setEditField] = useState('name')
  const [dataSource, setDataSource] = useState<any>([])
  const [showAddRow, setShowAddRow] = useState(false)

  const [selectedTable, setSelectedTable] = useState('')
  const [selectedProject, setSelectedProject] = useState('')

  const [allProjects, setAllProjects] = useState<any>([])

  const onAddRow = () => {
    setShowAddRow(true)
  }
  const [newName, setNewName] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const fetchAndSetDataSource = async () => {
    setIsLoading(true)
    const tables = await composeTableDataSource()
    const projects = (await getProjects('table')).data.projects || []

    setAllProjects(projects.map(e => ({ label: e.name, value: e.id })))

    setDataSource(
      tables
    )
    setIsLoading(false)
  }

  useEffect(() => {


    fetchAndSetDataSource()
  }, [])

  const [projectName, setProjectName] = useState('')
  const [{ auth }] = useAuth()


  const renderFooter = () => {
    return <div>
      {
        showAddRow && <div>
          <input className="input w-full my-[10px]" placeholder="Enter Project Name" onChange={(e) => setProjectName(e.target.value)} />
          <div className="flex justify-end mt-2 gap-2">
            <button className="btn btn-primary"
              onClick={async () => {
                const res = await createProject('table', {
                  name: projectName
                })


                toast.success('created a new Table Project!', {
                  autoClose: 2000,
                  position: 'top-center',
                })
                track('Table - Create Project', {
                })
                setDataSource((prev: any) => [
                  prev[0],
                  {
                    ...(res.data as any).project,
                    key: (res.data as any).project.id,
                    type: 'folder',
                    children: []
                  },
                  ...prev.slice(1)
                ])
                setShowAddRow(false)
              }}
            >Save</button>
            <button className="btn btn-ghost" onClick={() => {
              setShowAddRow(false)
            }}>Cancel</button>
          </div>
        </div>
      }
      {
        !showAddRow && <div className="w-full text-left  border-b py-[8px]">
          <button onClick={() => {
            onAddRow()
          }} className="btn btn-ghost text-left w-full justify-start text-[14px] text-[#777A80] font-normal">
            <img width={14} src={addProjectIcon} className="mr-[10px] ml-[52px]" />
            Add Table Project
          </button>
        </div>
      }
    </div>
  }
  const columns = [
    {
      title: "TITLE",
      dataIndex: "name",
      key: "name",
      width: 250,
      sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      render: (text: any, record: any) => {
        const isProject = record.children
        return <div className={
          classNames(
            "flex gap-2 items-center",
            {
              'pl-7': !isProject
            }
          )

        }>{
            isProject ? <Link
              onClick={() => {
                track('Table - Open Project', {
                  projectId: record.id
                })
              }}
              className="flex gap-2 items-center" to={`/v2/workspace/tables/${record.id}/overview?from=workspace`}>
              <img src={folderIcon} width={14} />
              {text}
            </Link > :
              <Link
                onClick={() => {
                  track('Table - Open Table', {
                    tableId: record.id
                  })
                }}
                className="flex gap-2 items-center" to={`/v2/workspace/tables/${record.project_id || 'default'}/${record.id}?from=workspace`}>
                <img src={tableIcon} width={14} />
                <div className="line-clamp-1">
                  {text}
                </div>
              </Link >
          }
        </div>
      }
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "SCHEMA",
      dataIndex: "schema",
      key: "schema"
    },
    {
      title: "DATABASE",
      dataIndex: "database",
      key: "database",
    },
    {
      title: "CREATED",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any) => {
        if (!text) return ''
        return new Date(text).toLocaleDateString()
      }
    },
    {
      title: "UPDATED",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text: any) => {
        if (!text) return ''
        return new Date(text).toLocaleDateString()
      }
    }, {
      title: "AUTHOR",
      dataIndex: ["owner", "displayName"],
      key: "role"
    },
    {
      title: 'ACTION',
      dataIndex: '',
      key: 'x',
      render: (text: string, record: any, dataIndex: number) => {
        const dropdownItems: ItemType[] = []

        if (record.type !== 'folder' && record.project_id === 'default') {
          dropdownItems.push({
            key: '2', label: <div className="flex gap-2 items-center" onClick={(e) => {
              setIsEditModalOpen(true)
              setSelectedTable(record.id)
              setEditField('move')
            }}><BiMove /> Add to Project</div>
          })
        }

        if (record.type === 'folder') {
          dropdownItems.push({
            key: '1', label: <div className="flex gap-2 items-center" onClick={(e) => {
              setIsEditModalOpen(true)
              if (record.type === 'folder') {
                setSelectedProject(record.id)
              } else {
                setSelectedTable(record.id)
              }
              setEditField('name')
            }}>
              <BiEdit /> Rename
            </div>
          })
        }

        dropdownItems.push({
          key: '3', label: <Popconfirm title={`Are you sure to delete this ${record.type === 'folder' ? 'Project' : 'Table'}?`} onConfirm={async () => {
            if (record.type === 'folder') {
              await deleteProject(record.id)
              setDataSource((prev) => {
                return prev.filter((proj: any) => proj.id !== record.id)
              })
            } else {
              await deleteTable([record.id], record.project_id)
            }
            await fetchAndSetDataSource()
            toast.success(`Successfuly deleted ${record.type === 'folder' ? 'Project' : 'Table'}`, {
              autoClose: 2000,
              position: 'top-center',
            })
            track(`Table - Delete ${record.type === 'Project' ? 'Project' : 'Table'}`, {
              type: record.type,
              id: record.id
            })
          }} onCancel={() => { }} okButtonProps={{
            style: {
              backgroundColor: '#253cf7',
              borderColor: '#253cf7',
              fontWeight: '600'
            }
          }}>
            <div className="text-[#ec5e57] flex gap-2 items-center">
              <MdDelete /> Delete
            </div>
          </Popconfirm>
        },)



        if (record.name !== 'Default') {
          return <div className="cursor-pointer">
            <Dropdown menu={{
              items: dropdownItems
            }}>
              <IoIosMore />
            </Dropdown>

          </div>
        }
        return null
      },
    },
  ]

  const [checkedRows, setCheckedRows] = useState<string[]>([])

  return <>
    <div className={
      classNames({
        hidden: checkedRows.length === 0
      })
    }>
      <Button onClick={() => {
        setEditField('bulk')
        setIsEditModalOpen(true)
      }}>
        Bulk Move
      </Button>
    </div>
    <Table
      rowKey={(record: any) => {
        return `${record.type}-${record.id}-${record.project_id || 'folder'}`
      }}
      rowSelection={{
        hideSelectAll: true,
        type: 'checkbox',
        renderCell: (checked: any, record: any, index: any, originNode: any) => {
          if (record.type !== 'folder') {
            return <div className="flex justify-center">
              <input type="checkbox"
                checked={new Set(checkedRows).has(record.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckedRows((prev) => [
                      ...prev,
                      record.id
                    ])
                  } else {
                    setCheckedRows((prev) => {
                      return prev.filter((a) => a !== record.id)
                    })
                  }
                }}
              />
            </div>
          }

          return <input type="checkbox" disabled />
        },

      }}
      pagination={false}
      expandable={
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          expandIcon: ({ expanded, onExpand, record }: any) => {
            if (record.children) {
              return <span className="float-left mr-2 mt-1 cursor-pointer" onClick={e => onExpand(record, e)}>
                {expanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
              </span>
            }
            return null

          }
        }
      }
      dataSource={
        auth?.tenant !== 'zettablock.com' ?
          dataSource.filter((e: any) => e.id !== 'default') :
          dataSource}
      columns={columns}
      footer={renderFooter}
      loading={isLoading}
      className="project-table"
    />
    {
      isEditModalOpen && <Modal open={isEditModalOpen} onOk={() => { setIsEditModalOpen(false) }} onCancel={() => { setIsEditModalOpen(false) }} footer={null}>
        <div className="px-4 pt-5 pb-2">
          {
            editField === 'name' ? <>

              <Form.Item label="Name">
                <Input onChange={(e) => {
                  setNewName(e.target.value)
                }} />
              </Form.Item>
              <div className="mt-4 text-right">
                <button className="btn btn-primary" onClick={async () => {
                  await updateProjectMetadata(selectedProject, { name: newName })
                  setNewName('')
                  setIsEditModalOpen(false)
                  fetchAndSetDataSource()
                  toast.success('Successfuly updated project name', {
                    autoClose: 2000,
                    position: 'top-center',
                  })
                  track('Table - Update project name', {
                    projectId: selectedProject,
                    newName
                  })
                }}>
                  Update Name
                </button>
              </div>
            </>
              : <>

                <Select style={{ minWidth: '300px' }} placeholder="move to project" options={allProjects} onChange={(e) => {
                  setSelectedProject(e)
                }} />
                <div className="mt-4 text-right">
                  <button className="btn btn-primary" onClick={async () => {
                    setIsLoading(true)
                    if (editField === 'bulk') {
                      await addTablesToProject(checkedRows, selectedProject)
                      setCheckedRows([])
                    } else {
                      await addTablesToProject([selectedTable], selectedProject)
                      setSelectedTable('')
                    }
                    setSelectedProject('')

                    track('Table - Move to project', {
                      tableId: selectedTable,
                      projectId: selectedProject
                    })


                    toast.success(`Successfully moved table to project ${allProjects.find(e => e.value === selectedProject).label}`, {
                      autoClose: 2000,
                      position: 'top-center',
                    })
                    setIsEditModalOpen(false)
                    setIsLoading(false)
                    fetchAndSetDataSource()
                  }}>
                    Move {
                      isLoading && <LoadingIndicator />
                    }
                  </button>
                </div>
              </>
          }


        </div>
      </Modal >
    }
  </>
}


export default TableWorkspace