/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { ChatList } from './chat-list';
import { ChatPanel } from './chat-panel';
// eslint-disable-next-line import/no-unresolved
import { useChat, UseChatOptions } from 'ai/react';
import { useScrollAnchor } from './use-scroll-anchor';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useStorage } from '../../modules/storage';
import { chatCompletion } from '../../modules/apis/neo/chatCompletion';
import { CoreMessage } from 'ai';
import { ModelSelectItem } from './chat-prompt-form';
import { riskDeferred, useAIAuth } from '../OpenAuth/useOpenAuth';

export interface ChatProps extends React.ComponentProps<'div'> {
  id?: string;
}

export function useGenerateChat({
  initialInput = '',
  initialMessages = [],
  body,
}: UseChatOptions) {
  const [messages, setMessages] = useState<CoreMessage[]>(
    initialMessages as CoreMessage[]
  );
  const [input, setInput] = useState(initialInput);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = useCallback(async () => {
    setInput('');

    // Optimistically add user message UI
    // eslint-disable-next-line react/no-unstable-nested-components
    setMessages((currentMessages) => [
      ...currentMessages,
      {
        role: 'user',
        content: input,
      },
    ]);
    setIsLoading(true);
    try {
      // const result = await generateText({
      //   model: zettaai((body as any).service_id),
      //   messages: [...messages, { role: 'user', content: value }],
      // });
      // Submit and get response message
      const res = await chatCompletion({
        ...body,
        messages: [...messages, { role: 'user', content: input }],
      } as any);
      setIsLoading(false);
      setMessages((currentMessages) =>
        res.data
          ? currentMessages.concat(res.data as CoreMessage[])
          : currentMessages
      );
    } catch (e) {
      setIsLoading(false);
    }
  }, [input]);

  return {
    input,
    handleSubmit,
    handleInputChange: (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      setInput(e.target.value);
    },
    isLoading,
    messages,
  };
}

export function Chat({ id, className }: ChatProps) {
  const [auth] = useAIAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [service, setService] = useState<ModelSelectItem | undefined>(() => {
    if (searchParams.has('service_id')) {
      const service_id = searchParams.get('service_id')!;
      const [model_id, , modelName, modelVersion] = service_id.split('@');
      return {
        key: model_id,
        label: modelName,
        version: modelVersion,
        id: service_id,
      };
    }
    return undefined;
  });
  const handleSelect = (selectedItem: ModelSelectItem) => {
    setService(selectedItem);
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?service_id=${selectedItem.id}`
    );
  };
  const {
    messages: _messages,
    input,
    handleSubmit,
    handleInputChange,
    isLoading,
  } = useChat({
    streamProtocol: 'text',
    api: `${process.env.REACT_APP_ZETTAAI_BACKEND_API}/api/chat/completions`,
    body: {
      model: service?.id,
    },
    fetch(url, config = {}) {
      return riskDeferred.promise.then((headers: any) => {
        config.headers = {
          ...config.headers,
          ...headers,
        };
        return fetch(url, config);
      });
    },
  });
  const messages = _messages as CoreMessage[];
  const userId = auth?.user?.id;
  useStorage('newChatId', id);

  useEffect(() => {
    if (userId) {
      if (!location.pathname.includes('chat') && messages.length === 1) {
        window.history.replaceState({}, '', `/aiweb/chat/${id}`);
      }
    }
  }, [id, location.pathname, userId, messages]);

  const { messagesRef, scrollRef, visibilityRef, isAtBottom, scrollToBottom } =
    useScrollAnchor();

  return (
    <div
      className='group w-full overflow-auto pl-0 peer-[[data-state=open]]:lg:pl-[250px] peer-[[data-state=open]]:xl:pl-[300px]'
      ref={scrollRef}
    >
      <div
        className={cn('pb-[200px] pt-4 md:pt-10', className)}
        ref={messagesRef}
      >
        {messages.length ? (
          <ChatList
            messages={messages}
            isShared={false}
            isLogined={!!userId}
            isLoading={isLoading}
          />
        ) : null}
        <div className='w-full h-px' ref={visibilityRef} />
      </div>
      <ChatPanel
        id={id}
        input={input}
        onInput={handleInputChange}
        selectedItem={service}
        onSelect={handleSelect}
        isAtBottom={isAtBottom}
        scrollToBottom={scrollToBottom}
        disabled={isLoading}
        onSubmit={handleSubmit}
        messages={messages}
      />
    </div>
  );
}
