import cn from 'classnames';
import { DownOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';

interface ButtonScrollToBottomProps extends ButtonProps {
  isAtBottom: boolean;
  scrollToBottom: () => void;
}

export function ButtonScrollToBottom({
  className,
  isAtBottom,
  scrollToBottom,
  ...props
}: ButtonScrollToBottomProps) {
  return (
    <Button
      ghost
      size='small'
      className={cn(
        'absolute right-4 top-1 z-10 bg-background transition-opacity duration-300 sm:right-8 md:top-2',
        isAtBottom ? 'opacity-0' : 'opacity-100',
        className
      )}
      onClick={() => scrollToBottom()}
      {...props}
    >
      <DownOutlined rev={undefined} />
      <span className='sr-only'>Scroll to bottom</span>
    </Button>
  );
}
