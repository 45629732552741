import React from 'react';

import { useCopyToClipboard } from './use-copy-to-clipboard';
import { Chat } from '../../modules/zettaai';
import { Modal, ModalProps } from 'antd';
import { toast } from 'react-toastify';
import { CoreMessage } from 'ai';

interface ChatShareDialogProps extends ModalProps {
  chat: Pick<Chat, 'id' | 'title'> & { messages: CoreMessage[] };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shareChat: (id: string) => any;
  onCopy: () => void;
}

export function ChatShareDialog({
  chat,
  shareChat,
  onCopy,
  ...props
}: ChatShareDialogProps) {
  const { copyToClipboard } = useCopyToClipboard({ timeout: 1000 });
  const [isSharePending, startShareTransition] = React.useTransition();

  const copyShareLink = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (chat: Chat) => {
      if (!chat.sharePath) {
        toast.error('Could not copy share link to clipboard');
      } else {
        const url = new URL(window.location.href);
        url.pathname = chat.sharePath;
        copyToClipboard(url.toString());
        onCopy();
        toast.success('Share link copied to clipboard');
      }
    },
    [copyToClipboard, onCopy]
  );

  return (
    <Modal
      {...props}
      title={
        <div>
          Share link to chat{' '}
          <span>Anyone with the URL will be able to view the shared chat.</span>
        </div>
      }
      okButtonProps={{
        disabled: isSharePending,
        children: isSharePending ? <>Copying...</> : <>Copy link</>,
      }}
      onOk={() => {
        // @ts-ignore
        startShareTransition(async () => {
          const result = await shareChat(chat.id);

          if (result && 'error' in result) {
            toast.error(result.error);
            return;
          }

          copyShareLink(result);
        });
      }}
    >
      <div className='p-4 space-y-1 text-sm border rounded-md'>
        <div className='font-medium'>{chat.title}</div>
        <div className='text-muted-foreground'>
          {chat.messages.length} messages
        </div>
      </div>
    </Modal>
  );
}
