import { useContext, useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router';
import { track } from '../../../v2/tracking';
import { useAIAuth } from '../../components/OpenAuth/useOpenAuth';
import { Button, Layout, List, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Header, Content } from 'antd/es/layout/layout';
import { Link } from 'react-router-dom';
import Logo from '../../../components/app/Logo';
import { Avatar } from '../../components/Avatar';
import { useNavigator } from '../../useNavigator';
import classNames from 'classnames';
import { IoIosSearch } from 'react-icons/io';

import { GlobalSettingsContext } from '../../../v2/context/global-settings';
import { MenuItemType } from 'antd/es/menu/interface';

export default function AuthLayout({
  children,
  menus,
}: {
  children?: React.ReactNode;
  menus: MenuItemType[];
}) {
  const navigator = useNavigator();
  const location = useLocation();
  const [auth] = useAIAuth();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { setIsMiniSidebar } = useContext(GlobalSettingsContext);

  const contentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    track(`Page View: ${location.pathname}`, {
      path: location.pathname,
      search: location.search,
      user: auth?.user?.id,
      email: auth?.user?.email,
    });
    contentRef.current?.scrollTo(0, 0);
  }, [location]);

  return (
    <Layout className='bg-white min-h-screen'>
      <Header style={{ padding: 0, height: 40, background: colorBgContainer }}>
        <div className='relative flex justify-between h-10 border-b items-center bg-[#fff] box-content	'>
          <div className='flex items-center ml-[40px]'>
            <Link
              to='/aiweb/models'
              onClick={() => {
                setIsMiniSidebar?.(false);
              }}
            >
              <Logo className='w-[123px]' />
            </Link>
            <div
              className={classNames(
                'flex items-center px-3 pl-[10px] bg-[#f4f4f4] rounded-[4px] ml-[82px] w-[350px]'
              )}
            >
              <IoIosSearch size={16} />
              <input
                type='text'
                placeholder='Search models, datasets...'
                className='h30 h-[30px] focus:outline-none input w-full bg-transparent text-[12px] pl-[10px] py-0 outline-none	'
              />
            </div>
          </div>
          <div className='flex-1'></div>
          <div className='flex items-center gap-[20px] p-4'>
            <div className='flex items-center gap-[30px]'>
              <a
                href='https://www.zettablock.com/pricing'
                target='_blank'
                rel='noreferrer'
                className='text-[#2B2F33] text-[12px] font-[500]'
              >
                Pricing
              </a>
              <a
                href='https://docs.zettablock.com/reference/api-intro'
                target='_blank'
                rel='noreferrer'
                className='text-[#2B2F33] text-[12px] font-[500]'
              >
                API
              </a>
              <a
                href='https://docs.zettablock.com/'
                target='_blank'
                rel='noreferrer'
                className='text-[#2B2F33] text-[12px] font-[500]'
              >
                Docs
              </a>
            </div>
            <Avatar
              className='w-[28px]'
              onClick={() => {
                navigator.push('/aiweb/profile');
              }}
            />
          </div>
        </div>
      </Header>
      <Layout
        className='w-[60%] min-w-[1024px] m-auto'
        style={{
          padding: 24,
          minHeight: 280,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Sider theme='light' width={300}>
          <List
            header={
              <div className='flex gap-2 items-center'>
                <Avatar />
                <div>{auth?.user?.user_name}</div>
              </div>
            }
            style={{ background: '#fbfcfd' }}
            bordered
            dataSource={menus}
            renderItem={(item) =>
              item ? (
                <List.Item
                  key={item.key}
                  style={{
                    background: location.pathname === item.key ? '#f8f7fa' : '',
                  }}
                >
                  <Button
                    type={location.pathname === item.key ? 'link' : 'text'}
                    size='small'
                    onClick={() => {
                      navigator.push(item.key as string);
                    }}
                  >
                    {item.label}
                  </Button>
                </List.Item>
              ) : null
            }
          />
        </Sider>
        <Content ref={contentRef} className='px-8'>
          {children || <Outlet />}
        </Content>
      </Layout>
    </Layout>
  );
}
